.dmn-js-parent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.dmn-js-parent .dmn-literal-expression-container,
.dmn-js-parent .dmn-decision-table-container {
    padding: 10px 10px 60px 10px;
}

body.intro .canvas {
    display: none;
}
